import React from 'react';
import Layout from '../../../layouts/CraftKitchensLayout';

import backArrow from '../../../images/black-arrow-left.svg';

const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			title={'Craft Kitchens | Employment Opportunities'}
			desc={'Craft Kitchens | Employment Opportunities'}
			headerType="dark"
		>
			<section>
				<div className="container mx-auto px-6">
					<div className="w-full max-md:px-[16px]">
						<div className="w-full pt-[48px] pb-[80px] text-left max-md:pb-10 max-md:pt-[32px]">
							<a href="/craftkitchens/" className="text-xl">
								<button className="flex flex-row items-center !text-black">
									<img
										src={backArrow}
										width={16}
										className="mr-[6px]"
									/>
									Back
								</button>
							</a>
							<h2 className="font-song-myung text-[42px] text-black">
								{pageContext.title}
							</h2>
							<p className="c-[#333333] font-work-sans text-base font-bold uppercase leading-[28px] max-lg:hidden">
								Location{' '}
								<span className="c-[#333333] pb-[20px] text-base font-normal leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</span>
							</p>
						</div>
					</div>
					<div className="flex flex-row pb-16 max-lg:flex-col-reverse">
						<div className="flex w-70 min-w-[none] flex-col items-start justify-start max-lg:w-full">
							<div className="mb-14 overflow-x-auto pb-4 max-lg:w-full">
								<p
									dangerouslySetInnerHTML={{
										__html: pageContext.description
									}}
								/>
							</div>
							<a
								className="ApplyButton ApplyButtonBottom"
								href={pageContext.url}
								target={'_blank'}
							>
								<button className="flex h-[44px] w-fit items-center justify-center gap-3 rounded-full border border-black bg-black px-10 font-work-sans text-lg font-medium uppercase leading-[28px] text-white transition-all hover:bg-white hover:text-black">
									APPLY NOW
								</button>
							</a>
						</div>
						<div className="ml-[36px] flex  w-30 flex-col items-start justify-start font-work-sans max-lg:ml-0 max-lg:mb-[24px] max-lg:w-full">
							<div className="w-full  bg-black px-[24px] py-9 text-white">
								<p className="c-[#333333]  text-base font-bold uppercase leading-[28px]">
									Location
								</p>
								<p className="c-[#333333] pb-[20px]  text-base leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</p>
								<p className="c-[#333333]   text-base font-bold uppercase leading-[28px]">
									POSTED
								</p>
								<p className="c-[#333333] pb-[20px]  text-base leading-[28px] max-lg:pb-2">
									{pageContext.postingDate}
								</p>

								<div className="w-full pt-[28px] text-center max-lg:text-left">
									<a
										className="ApplyButton ApplyButtonTop"
										href={pageContext.url}
										target={'_blank'}
									>
										<button className="text-4 flex h-[44px] w-fit items-center justify-center gap-3 rounded-full border border-white bg-white px-10 font-work-sans text-lg font-medium uppercase leading-[28px] text-black transition-all hover:bg-black hover:text-white">
											APPLY NOW
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
