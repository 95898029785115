import React from 'react';
import JobDescription from '../components/JobDescription/JobDescription';
import JobDescriptionBrite from '../components/briteworx/JobDescription/JobDescription';
import JobDescriptionCraft from '../components/craftkitchens/JobDescription/JobDescription';
import JobDescriptionWallis from '../components/wallis/JobDescription/JobDescription';

import './jobDescription.css';

const JobDescriptionParent = ({ pageContext }: any) => {
	const brand = pageContext.brand;

	if (brand === 'Wallis Co.') {
		return <JobDescription pageContext={pageContext} />;
	} else if (brand === 'Brite WorX') {
		return <JobDescriptionBrite pageContext={pageContext} />;
	} else if (brand === 'Craft Kitchens') {
		return <JobDescriptionCraft pageContext={pageContext} />;
	} else if (brand == "Wallis Co. Lubricants" || brand == "Wallis Co. Transport") {
		return <JobDescriptionWallis pageContext={pageContext} />;
	}
};

export default JobDescriptionParent;
